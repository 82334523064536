<template>
    <div class="account-content">
        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
            <div class="section-title margin-deliver">{{ $t("account information") }}</div>
            <button class="button primary-button" v-if="company" @click.prevent="switchToCandidate">
                {{ $t('switch to candidate') }}
            </button>
        </div>
        <div class="row">
            <div class="col-md-7 col-sm-6 mb-4">
                <div class="billing-card details__card gutter-10">
                    <div class="details__card__head">
                        <h4 class="font-weight-bold">{{ $t("Information") }}</h4>
                    </div>
                    <div class="form-box pt-2">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <div class="image-selector">
                                    <div class="img-box">
                                        <img :src="additionalInfo.photo" :alt="candidate.first_name"/>

                                        <a href="#" class="remove-button"
                                           v-if="hasPhoto"
                                           @click.prevent="removePhoto">
                                            <i class="eicon e-delete"></i>
                                        </a>
                                    </div>
                                    <label class="file-browse-button">
                                        <span class="text-capitalize p-2">{{ $t("change") }}</span>
                                        <input
                                                id="profile_image"
                                                ref="profile_image"
                                                type="file"
                                                accept=".png, .jpg, .jpeg"
                                                @change="setCroppieConfig(175, 175, 175, 175); croppie('profile_image', $event)"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="text-capitalize">{{ $t("First name") }}*</label>
                                <input type="text" maxlength="50" class="form-control"
                                       :placeholder="$t('enter your first name')" v-model="candidate.first_name">
                                <error-message :message="errors.first_name"/>

                            </div>
                            <div class="form-group col-md-6">
                                <label class="text-capitalize">{{ $t("Last name") }}*</label>
                                <input type="text" maxlength="50" class="form-control"
                                       :placeholder="$t('enter your last name')" v-model="candidate.last_name">
                                <error-message :message="errors.last_name"/>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="text-capitalize">{{ $t("email address") }}</label>
                            <v-popover offset="10" :disabled="false" style="display:initial;">
                                <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                                <template slot="popover">
                                    <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                    <div style="text-align:left;">
                                        {{ $t(`Note: Email cannot be changed as it will need to verify the domain again. Please contact support for help.`) }}
                                    </div>
                                </template>
                            </v-popover>
                            <input type="email" class="form-control" readonly
                                   :placeholder="$t('enter your email address')" v-model="candidate.email">
                            <error-message :message="errors.email"/>

                        </div>
                        <div class="form-group">
                            <label class="text-capitalize">{{ $t("phone number") }}* </label>
                            <input type="text" maxlength="20" class="form-control"
                                   :placeholder="$t('enter your phone number')" v-model="candidate.mobile_number">
                            <error-message :message="errors.mobile_number"/>
                        </div>

                        <div class="form-group mt-4 text-right mb-0">
                            <submit-button :loading="isBasicLoading" :click="saveBasicInfo">{{ $t("update") }}
                            </submit-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5 col-sm-6 mb-4">
                <form action="">
                    <div class="billing-card details__card gutter-10">
                        <div class="details__card__head">
                            <h4 class="font-weight-bold">{{ $t("Change Password") }}</h4>
                        </div>
                        <div class="form-box">
                            <div class="form-group">
                                <label for="new_password">{{ $t("Current Password") }}*</label>
                                <input type="password" id="old_password" v-model="form.old_password"
                                       class="form-control" placeholder="********"/>
                                <error-message :message="errors.old_password"/>
                            </div>
                            <div class="form-group">
                                <label for="new_password">{{ $t("New Password") }}*</label>
                                <input type="password" id="new_password" v-model="form.new_password"
                                       class="form-control" placeholder="********"/>
                                <error-message :message="errors.new_password"/>
                            </div>

                            <div class="form-group">
                                <label for="confirm_password">{{ $t("Confirm Password") }}*</label>
                                <input type="password" id="confirm_password" v-model="form.confirm_password"
                                       class="form-control" placeholder="********"/>
                                <error-message :message="errors.confirm_password"/>
                            </div>

                            <div class="form-group mt-4 text-right">
                                <submit-button :loading="isLoading" :click="updatePassword">
                                    {{ $t("Change Password") }}
                                </submit-button>
                            </div>
                            <div class="form-group">
                                <a class="inline-doc mt-4" href="https://easy.jobs/docs/reset-password" target="_blank">
                                    {{ $t('How to Change Password?') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="showCropperModel" class="modal vue-cropper show" id="vue-cropper-modal" style="display: block">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content" v-on-click-away="closeCropperModal">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t("Resize Image") }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" @click="closeCropperModal">&times;</span>
                        </button>
                    </div>
                    <div id="image-crop-wrapper" class="modal-body croppie-container ready">
                        <vue-croppie
                                ref="croppieRef"
                                :enableOrientation="true"
                                :enableResize="false"
                                :boundary="croppieShowOption.boundary"
                                :viewport="croppieShowOption.viewport">
                        </vue-croppie>
                    </div>

                    <div class="modal-footer flex justify-content-between">
                        <button type="button" class="button warning-button" @click="closeCropperModal">{{ $t(`Close`) }}
                        </button>
                        <button type="button" @click.prevent="cropImage()" class="button success-button" :class="{'disabled' : uploadingImage}">
                            <span v-if="uploadingImage">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="mr-2" style="margin: auto; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <circle cx="50" cy="50" r="24" stroke-width="8" stroke="#fff" stroke-dasharray="37.69911184307752 37.69911184307752" fill="none" stroke-linecap="round" transform="rotate(300.791 50 50)">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.01010101010101s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                                    </circle>
                                </svg>
                                {{ $t("please wait") }}
                            </span>
                            <template v-else>{{ $t(`Save`) }}</template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showCropperModel" class="modal-backdrop fade show"></div>
    </div>

</template>

<script>
import client from "../../app/api/Client";
import {getCandidateInfo as loadCandidateInfo} from "../../app/api/CommonRequest";
import {SWITCH_TO_ACCOUNT, UPDATE_USER_DATA} from "../../constants/action-type";
import {mapActions, mapState} from "vuex";
import VueCroppie from "vue-croppie";
import 'croppie/croppie.css';
import Vue from "vue";
const ErrorMessage = () => import("../../components/common/ErrorMessage.vue");
Vue.use(VueCroppie);

export default {
    components: {ErrorMessage},
    data() {
        return {
            form: {
                old_password: '',
                new_password: '',
                confirm_password: '',
            },

            candidate: {
                first_name: '',
                last_name: '',
                email: '',
                mobile_number: '',
            },
            additionalInfo: {
                photo: ''
            },
            errors: [],
            isLoading: false,
            isBasicLoading: false,
            hasPhoto: false,
            showCropperModel: false,
            croppieUploadOption: {
                enableExif: true,
                quality: 1,
                // format: 'jpeg',
                size: {
                    width: null,
                    height: null
                },
            },
            croppieShowOption: {
                boundary: {
                    width: null,
                    height: null,
                },
                viewport: {
                    width: null,
                    height: null,
                }
            },
            uploadingImage: false
        }
    },
    computed: {
        ...mapState(['company'])
    },
    methods: {
        ...mapActions([UPDATE_USER_DATA]),
        async switchToCandidate() {
            this[SWITCH_TO_ACCOUNT](null);
            let {data} = await client().patch('/user/change-company');
            return await this.$router.push({name: 'dashboard'});
        },

        async updatePassword() {
            this.isLoading = true;
            this.errors = [];
            try {
                let {data} = await client().put(`/my-account/change-password`, this.form);
                this.$toast.success(this.$t(data.message));
                this.form.new_password = '';
                this.form.old_password = '';
                this.form.confirm_password = '';
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                } else {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
            this.isLoading = false;
        },
        async saveBasicInfo() {
            this.isBasicLoading = true;
            this.errors = [];
            try {
                let {data} = await client().post(`/my-account/information`, this.candidate);
                this[UPDATE_USER_DATA](data.data);
                this.$toast.success(this.$t(data.message));
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                } else {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
            this.isBasicLoading = false;
        },
        async getCandidateInfo() {
            this.isContentLoading = true;
            try {
                let {data} = await loadCandidateInfo();
                this.candidate.first_name = data.candidate.first_name;
                this.candidate.last_name = data.candidate.last_name;
                this.candidate.email = data.candidate.email;
                this.candidate.mobile_number = data.candidate.mobile_number;
                this.hasPhoto = data.additional.hasPhoto;
                this.additionalInfo.photo = data.additional.photo;
            } catch (e) {
            }
            this.isContentLoading = false;

        },
        async removePhoto() {
            try {
                let confirm = await this.$dialog.confirm({
                    title: this.$t(`Confirmation`),
                    body: this.$t(`Are you sure you want to remove profile photo?`)
                });

                if (!confirm) return false;

                let {data: {data: user, message}} = await client().post('/candidate/photo', {
                    _method: 'DELETE'
                });

                this.additionalInfo.photo = user.profile_image;
                this.hasPhoto = false;
                this[UPDATE_USER_DATA](user);
                this.$toast.success(message);
            } catch (e) {
            }
        },

        croppie(target, e) {

            this.showCropperModal();

            this.target = target;

            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.closeCropperModal();
                return;
            }


            this.tempfile = files[0];
            let allowedFileExists = ['png', 'jpg', 'jpeg'];

            if (!allowedFileExists.includes(this.tempfile.name.split('.').pop().toLowerCase())) {
                this.closeCropperModal();
                this.$toast.error(this.$t(`Invalid image format.`));
                return;
            }

            var reader = new FileReader();

            reader.onload = e => {
                this.$refs.croppieRef.bind({
                    url: e.target.result
                });
            };

            reader.readAsDataURL(this.tempfile);
        },

        cropImage() {

            let croppedImage = this.$refs.croppieRef;

            croppedImage.result(this.croppieUploadOption, base64 => {

                fetch(base64).then(e => e.blob()).then((blob) => {

                    const formData = new FormData();

                    formData.append('photo', blob, this.tempfile.name);

                    this.uploadingImage = true;

                    client()
                        .post('/candidate/photo', formData)
                        .then(({data}) => {

                            this.additionalInfo.photo = base64;

                            this.uploadingImage = false;

                            this.hasPhoto = true;

                            this[UPDATE_USER_DATA](data.data);

                            this.closeCropperModal();

                            return this.$toast.success(this.$t(data.message));

                        }).catch(error => {

                        this.showErrorText(error)
                    }).finally(() => {
                        this.uploadingImage = false;
                    });
                })
            });
        },

        showErrorText(error) {
            if (error.response !== undefined && [422, 400].includes(error.response.status)) {/* validation error */
                if (typeof error.response.data.message == 'string') {
                    return this.$toast.error(error.response.data.message ?? '');/* System error */
                }
                this.errors = error.response.data.message;
                return this.$toast.error('Oops! Please check your input');
            } else {
                // this.$toast.error('Oops! Somethings was wrong');
            }
        },

        setCroppieConfig(vWidth, vHeight, width, height) {

            this.croppieUploadOption.size.width = width;
            this.croppieUploadOption.size.height = height;
            this.croppieShowOption.viewport.width = vWidth;
            this.croppieShowOption.viewport.height = vHeight;

            this.croppieShowOption.boundary.width = vWidth + 100;
            this.croppieShowOption.boundary.height = vHeight + 100;

        },

        showCropperModal() {
            this.showCropperModel = true;
            const el = document.body;
            el.classList.add('modal-open');
        },

        closeCropperModal() {
            document.getElementById('profile_image').value = null;
            this.showCropperModel = false;
            this.fade = false;
            const el = document.body;
            el.classList.remove('modal-open');
        },
    },

    async mounted() {
        await this.getCandidateInfo();
    }
}
</script>

<style>
.notices .toast .toast-text {
    word-break: initial !important;
}
</style>
<style scoped>
.img-box a.remove-button {
    position: absolute;
    left: 20px;
    color: rgba(245, 245, 245, 1);
    background: #ff5f74;
    top: 4px;
    font-size: .7em;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 2px;
}
</style>
